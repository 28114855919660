import moment from 'moment'
import Axios from 'axios'
const axios = Axios.create({
  baseURL: 'https://mdty5x5nqk.execute-api.us-east-1.amazonaws.com/'
})


const practitionerTime2Appointment = (start_date, { id: practitioner_id, name: practitioner_name } = {}, { id: location_id, name: location_name } = {}) => ({
  start_date,
  duration: 30,
  status: 'Free',
  service_name: '',
  practitioner_id,
  practitioner_name,
  location_id,
  location_name
})

const getCalendarData = ({ dateStart, dateEnd }) => axios.get('/intakeq/calendar', { params: { dateStart: moment(dateStart).format('YYYY-MM-DD'), dateEnd: moment(dateEnd).format('YYYY-MM-DD') } }).then(res => res.data && res.data.data)

export const queryCalendarData = async ({ dateStart, dateEnd, practitionerIds, locationIds }) => {
  dateStart = moment(dateStart)
  dateEnd = moment(dateEnd)
  const filterByPractitionerIds = (entities, idProp) => practitionerIds && practitionerIds.length ?
    entities.filter(t => practitionerIds.includes(t[idProp])) :
    entities
  const hasIntersection = (a, b) => a.findIndex(x => b.includes(x)) > -1
  const filterByLocationIds = (entities, idProp) => locationIds && locationIds.length ?
    entities.filter(t => idProp.endsWith('s') ? hasIntersection(locationIds, t[idProp]) : locationIds.includes(parseInt(t[idProp]))) :
    entities
  const filterByDateRange = (entities, timeProp) => timeProp ?
    entities.filter(t => moment(t[timeProp]).isBetween(dateStart, dateEnd, null, '[]')) :
    entities.filter(t => moment(t).isBetween(dateStart, dateEnd, null, '[]'))
  const { appointments, practitioners, locations } = await getCalendarData({ dateStart, dateEnd })
  const rangedAppointments = filterByDateRange(filterByPractitionerIds(filterByLocationIds(appointments, 'location_id'), 'practitioner_id'), 'start_date')
  const rangedFreeAppointments = filterByPractitionerIds(filterByLocationIds(practitioners, 'locationIds'), 'id').map(p => filterByDateRange(p.availableTimes).map(t => practitionerTime2Appointment(t, p))).flat()
  return {
    practitioners,
    locations,
    appointments: [...rangedAppointments, ...rangedFreeAppointments]
  }
}

export const queryBookingData = async ({ dateStart, dateEnd, practitionerIds, locationIds }) => {
  dateStart = moment(dateStart)
  dateEnd = moment(dateEnd)
  const filterByPractitionerIds = (entities, idProp) => practitionerIds && practitionerIds.length ?
    entities.filter(t => practitionerIds.includes(t[idProp])) :
    entities
  const hasIntersection = (a, b) => a.findIndex(x => b.includes(x)) > -1
  const filterByLocationIds = (entities, idProp) => locationIds && locationIds.length ?
    entities.filter(t => idProp.endsWith('s') ? hasIntersection(locationIds, t[idProp]) : locationIds.includes(parseInt(t[idProp]))) :
    entities
  const filterByDateRange = (entities, timeProp) => timeProp ?
    entities.filter(t => moment(t[timeProp]).isBetween(dateStart, dateEnd, null, '[]')) :
    entities.filter(t => moment(t).isBetween(dateStart, dateEnd, null, '[]'))
  const { appointments, practitioners, locations } = await getCalendarData({ dateStart, dateEnd })
  const locationMap = locations.reduce((map, l) => { map[l.id] = l; return map; }, {})
  // console.log(locationMap)
  const rangedAppointments = filterByDateRange(filterByPractitionerIds(filterByLocationIds(appointments, 'location_id'), 'practitioner_id'), 'start_date')
  const rangedFreeAppointments = filterByPractitionerIds(filterByLocationIds(practitioners, 'locationIds'), 'id').map(p => p.locationIds.map(l => filterByDateRange(p.availableTimes).map(t => practitionerTime2Appointment(t, p, locationMap[l]))).flat()).flat()
  return {
    practitioners,
    locations,
    appointments: [...rangedAppointments, ...rangedFreeAppointments]
  }
}